<template>
  <div
    v-if="data && blogEntries.length"
    class="website-container blog-archive-wrapper"
  >
    <div class="title-wrapper">
      <h1>{{data[slug].fields.prettyName}}</h1>
      <h2 class="tagline">{{data[slug].fields.ogDescription}}</h2>
    </div>
    <transition-group
      name="slide" tag="div" class="website-container-inner"
    >
      <div
        v-for="(content, key) in data"
        v-if="blogEntries.includes(key)"
        :key="'path_' + slug + '_' + key + content.sys.id"
        class="blog-item hero-wrapper wrap-cta-true no-image-true"
        :class="`hero-background-color-${content.fields.style}`"
        :style="{backgroundImage: `url(${content.fields.style === 'Image' ? content.fields.ogImage.fields.file.url : ''})`}"
      >
        <router-link :to="`${slug}/${content.fields.alias}`" class="hero-content">
          <div class="imagex" v-if="content.fields.style === 'Image'">
          </div>
          <div class="hero-inner-content">
            <div class="text">
              <h1 class="title">{{content.fields.prettyName}}</h1>
              <h2 v-html="content.fields.ogDescription"></h2>
              <div class="chevron-cta">
                <Icons
                  class="icon"
                  icon="chevron-right"
                  size="64"
                  :color="'rgba(255,255,255,0.4)'"
                ></Icons>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </transition-group>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'WebContainer',

  transition: 'page',

  props: [ 'data', 'slug' ],

  data () {
    return {
      blogEntries: []
    }
  },

  components: {
    Icons: () => import('Components/base/Icons')
  },

  methods: {
  },

  computed: {
    siteContent () {
      return this.$store.state.site.content
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150)

    var allRoutes = Object.keys(this.data)

    this.blogEntries = allRoutes.filter(route => {
      return route.indexOf(`${this.slug}/`) === 0
    })

    if (this.$route.fullPath.indexOf('/es') === 0) {
      this.$store.commit('SET_SITE_LANG', { lang: 'ES' })
    } else {
      this.$store.commit('SET_SITE_LANG', { lang: 'EN' })      
    }
  }
}

</script>

<style scoped lang="scss">
.tagline {
  color: $gray-medium !important;
  max-width: 810px;
  margin: auto;
}
.title-wrapper {
  padding: 2em 1em;
}
.chevron-cta {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  transform: translateX(-5px);
  max-width: 50px;
  overflow: hidden;
  @include breakpoint($air) {
    transform: translateX(-80px) scale(1.5);
  }
  @include breakpoint($fullhd) {
    transform: translateX(-80px) scale(1.5);
  }
}
.hero-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  @include breakpoint($tablet) {
    flex-direction: column;
    text-align: center;
    margin-left: 2em;
    margin-right: 2em;
  }
}

.wrap-cta-true {
  position: relative;
  height: auto;
  padding-bottom: 0;
  min-height: 0;
  @include breakpoint($tablet) {
    transition: all 0.4s ease-in-out;
    h1,
    h2 {
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      h1,
      h2 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.03);
      }
      h2 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.08);
      }
    }
  }
  &.alignment-undefined {
    text-align: left;
    @include breakpoint($tablet) {
      text-align: center;
    }
  }
  .hero-content {
    padding-right: 3em;
    width: calc(100% - 4em);
    @include breakpoint($tablet) {
      padding-right: 1em;
      width: calc(100% - 2em);
      justify-content: center;
    }
  }
}
.wrap-cta-true {
  position: relative;
  @include breakpoint($tablet) {
    transition: all 0.4s ease-in-out;
    h1,
    h2 {
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      h1,
      h2 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.03);
      }
      h2 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.08);
      }
    }
  }
  &.alignment-undefined {
    text-align: left;
    @include breakpoint($tablet) {
      text-align: center;
    }
  }
  .hero-content {
    padding-right: 3em;
    width: calc(100% - 4em);
    @include breakpoint($tablet) {
      padding-right: 1em;
      width: calc(100% - 2em);
      justify-content: center;
    }
  }
}

.hero-image {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  min-height: 300px;

  @include breakpoint($tablet) {
    position: absolute;
    top: 0;
    margin-bottom: 1em;
  }
}

.hero-wrapper {
  position: relative;
  background-color: white;
  flex: 1;
  @include breakpoint($tablet) {
    height: 0;
    padding-bottom: 36%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @include breakpoint($air) {
    padding-bottom: 32%;
  }
  &.no-image-true {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include breakpoint($tablet) {
      height: auto;
      padding-bottom: 0;
      min-height: 0;
    }
  }
  &.hero-background-color-light {
    background-color: rgba($gray-light, 0.6);
  }
  &.hero-background-color-Magenta,
  &.hero-background-color-Purple {
    background-color: $color-wrapper-emphasis-lighter;
    h1 {
      color: rgba(white, 0.99);
    }
    h2 {
      color: rgba(white, 0.7);
    }
    h4 {
      color: rgba(white, 0.7);
    }
    button {
      background-color: $text-main !important;
      border-color: $text-main !important;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: rgba($text-main, 0.8) !important;
        border-color: rgba($text-main, 0.8) !important;
      }
    }
  }
  &.hero-background-color-Purple {
    background-color: $color-wrapper-emphasis;
  }
  &.hero-background-color-Dark {
    background-color: rgba($text-main, 0.99);
    h1 {
      color: rgba(white, 0.99);
    }
    h2 {
      color: rgba(white, 0.6);
    }
  }
  &.hero-background-color-Purple,
  &.hero-background-color-Magenta,
  &.hero-background-color-Dark {
    .description {
      color: rgba(white, 0.99);
    }    
  }
  h2 {
    color: rgba($text-main, 0.7);
  }
  &.no-content-true {
    @include breakpoint($tablet) {
      padding-bottom: 25%;
    }
  }
  &.hero-background-color-Image {
    padding-top: 160px;
    background-size: cover;
    background-position: center;
    @include breakpoint($tablet) {
      padding-top: 0;
    }
    .hero-content {
      background: rgba(white, 0.7);
      @include breakpoint($tablet) {
        min-height: 360px;
      }
    }
  }
}
.hero-content {
  padding: 1em 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  @include breakpoint($tablet) {
    flex-direction: row;
  }
  &.bg-image {
    @include breakpoint($tablet) {
      padding: 1em;
      height: calc(100% - 2em);
      position: absolute;
      width: calc(100% - 2em);
      top: 0;
      background: rgba(white, 0.69);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.alignment-Left {
  .hero-content {
    @include breakpoint($tablet) {
      text-align: left;
      width: 100%;
      &.bg-image {
        @include breakpoint($tablet) {
          align-items: flex-start;
          left: 0;
          width: calc(100% - 2em);
          max-width: calc(50% - 3em);
          padding-right: 3em;
          border-bottom-right-radius: 1500px;
        }
      }
      h1 {
        text-align: left;
      }
    }
  }
}
.alignment-Right {
  .hero-content {
    @include breakpoint($tablet) {
      text-align: right;
      &.bg-image {
        @include breakpoint($tablet) {
          align-items: flex-end;
          max-width: calc(50% - 3em);
          padding-left: 3em;
          border-bottom-left-radius: 1500px;
          right: 0;
        }
      }
      h1 {
        text-align: right;
      }
    }
  }
}
.alignment-undefined.no-image-false {
  .hero-content {
    @include breakpoint($tablet) {
      &.bg-image {
        background: rgba($text-main, 0.69);
      }
      text-align: center;
      h1 {
        color: white;
      }
      h2 {
        color: rgba(white, 0.8);
      }
    }
  }
}

.image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 0;
  padding: 50%;
}
.image-set-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .image-set-item {
    width: 150px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 1em;
    @include breakpoint($tablet) {
      width: 200px;
      height: 120px;
      margin: 0.5em 1em;
    }
  }
}

h4 + h1 {
  margin-top: -20px;
  @include breakpoint($tablet) {
    margin-top: -30px;
  }
}
.cta-button {
  margin-bottom: 1em;
}
.description {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.alignment-Left {
  .description {
    max-width: 100%;
    margin-left: 0;
    text-align: left;
  }
}

.hero-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint($tablet) {
    flex-direction: column;
  }
}

a {
  text-decoration: none !important;
  h1,
  h2 {
    text-decoration: none !important;
  }
}
</style>
<style lang="scss">
.wrap-cta-true {
  position: relative;
  @include breakpoint($tablet) {
    h4 {
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      transition: all 0.4s ease-in-out;
      h4 {
        transition: all 0.52s ease-in-out;
        transform: scale(1.03);
      }
    }
  }
}
.hero-wrapper.no-image-false {
  .description {
    @include breakpoint($tablet) {
      color: rgba(white, 0.99);
    }
  }

}
</style>