import { render, staticRenderFns } from "./WebArchive.vue?vue&type=template&id=0204a1ce&scoped=true&"
import script from "./WebArchive.vue?vue&type=script&lang=js&"
export * from "./WebArchive.vue?vue&type=script&lang=js&"
import style0 from "./WebArchive.vue?vue&type=style&index=0&id=0204a1ce&prod&scoped=true&lang=scss&"
import style1 from "./WebArchive.vue?vue&type=style&index=1&id=0204a1ce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0204a1ce",
  null
  
)

export default component.exports